import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logoImg from "../../images/logo-full.png"

import "./index.scss"

const Header = ({ siteTitle, logo }) => {
  return (
    <>
      <header className="header">
        <div className="container mx-auto py-8 px-8 xl:px-12 flex flex-wrap flex-row items-center justify-center lg:grid header-container w-full justify-start items-center lg:grid-cols-2">
          <div className="logo">
            {logo && (
              <Link className="block" to="/">
                <img
                  className="img logo--light"
                  src={logoImg}
                  alt={siteTitle}
                />
              </Link>
            )}
          </div>
          <div className="menu ml-auto">
            <ul className="flex flex-nowrap">
              <li className="ml-0">
                <a
                  href="https://myfanclub.app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fs-12 fs-lg-12 fs-xl-14 font-bold px-6 py-2 md:px-8 md:py-3 btn btn-gradient"
                >
                  Visit Platform
                </a>
                {/* <Link
                  className="fs-12 fs-lg-14 fs-xl-16 text-white hover:text-white"
                  to="/"
                >
                  Get Started
                </Link> */}
              </li>
              <li className="ml-4 md:ml-8">
                <a
                  href="mailto:support@gofanclub.com"
                  className="fs-12 fs-lg-14 fs-xl-16 text-white hover:text-white"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  theme: PropTypes.string,
  siteTitle: PropTypes.string,
  logo: PropTypes.bool,
}

Header.defaultProps = {
  theme: "",
  siteTitle: ``,
  logo: true,
}

export default Header
