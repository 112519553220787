import React from "react"
import { Link } from "gatsby"
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi"

import LogoMain from "../../images/logo-full.png"

import "./index.scss"

const Footer = ({ siteTitle }) => {
  return (
    <footer className="relative">
      <div className="container mx-auto py-16 px-4 flex flex-wrap flex-col lg:flex-row items-center justify-center lg:grid footer-container">
        <div className="footer-icon">
          <div className="logo">
            <Link className="inline-block" to="/">
              <img
                className="img logo--light"
                src={LogoMain}
                alt={siteTitle}
                style={{ maxWidth: "unset", width: "150px" }}
              />
            </Link>
          </div>
        </div>
        <div className="menu flex align-center justify-center px-0">
          <ul className="flex flex-wrap justify-center flex-col lg:flex-row items-center w-full">
            <li className="mx-8 my-4 lg:my-0">
              <Link to="/terms-and-conditions">Terms</Link>
            </li>
            <li className="mx-8 my-4 lg:my-0">
              <Link to="/privacypolicy">Privacy</Link>
            </li>
            {/* <li className="mx-8 my-4 lg:my-0">
              <Link to="/faqs">FAQ's</Link>
            </li> */}
            <li className="mx-8 my-4 lg:my-0">
              <a href="mailto:support@gofanclub.com">Contact us</a>
            </li>
            {/* <li className="mx-8 my-4 lg:my-0">
              <Link to="/about-us">About us</Link>
            </li> */}
            {/* <li className="mx-8 my-4 lg:my-0">
              <Link to="/nft">NFTs</Link>
            </li> */}
            <li className="mx-8 my-4 lg:my-0">
              <a
                href="https://myfanclub.app"
                target="_blank"
                rel="noopener noreferrer"
                className="fs-12 fs-lg-14 fs-xl-16 font-bold px-4 py-2 md:px-8 md:py-3 btn btn-gradient"
              >
                Visit Platform
              </a>
            </li>
          </ul>
        </div>
        <div className="social-links flex flex-wrap items-center justify-center mt-4 lg:mt-0">
          <span>
            <a
              className="fs-28 mx-3 my-2 block"
              href="https://www.facebook.com/FanClubtheapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiFacebook>Facebook</FiFacebook>
            </a>
          </span>
          <span>
            <a
              className="fs-28 mx-3 my-2 block"
              href="https://twitter.com/FanClubapp_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiTwitter>Twitter</FiTwitter>
            </a>
          </span>
          <span>
            <a
              className="fs-28 mx-3 my-2 block"
              href="https://www.instagram.com/fanclubapp_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiInstagram>Instagram</FiInstagram>
            </a>
          </span>
          <span>
            <a
              className="fs-28 mx-3 my-2 block"
              href="http://www.linkedin.com/company/fanclub/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiLinkedin>LinkedIn</FiLinkedin>
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
