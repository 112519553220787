import React from "react"
import Layout from "../layout"
import SEO from "../components/seo"
import Section from "../components/Section"

const AboutUs = ({ location }) => {
  return (
    <Layout pageId="about-us" location={location}>
      <SEO title="About Us" />
      <Section id="about-us">
        <div className="container">
          <h1 className="fs-28 fs-lg-56 fs-xl-56 text-center font-bold ff-secondary text-gradient">
            About Us
          </h1>

          <div className="mt-16">
            <h2
              className="fs-32 fw-600 font-bold ff-primary my-8"
              style={{ color: "#F4E900" }}
            >
              Vision
            </h2>
            <p className="text-fourth">
              FanClub aspires to become the premier “Creator-first” social media
              platform aiming to power meaningful relationships through
              authenticity, individuality, and freedom of expression.
            </p>
            <h2
              className="fs-32 fw-600 font-bold ff-primary my-8"
              style={{ color: "#F4E900" }}
            >
              Company Mission
            </h2>
            <p className="text-fourth">
              FanClub’s mission is to fully empower Content Creators to take
              back ownership of their content, giving Creators the tools and
              freedom to fully monetize of off their own quality content and its
              accompanying reach. FanClub’s goal is to produce meaningful
              relationships between Creators and their fanbase in a supportive
              and positive community space on the platform.
            </p>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default AboutUs
