import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../components/Header"
import Footer from "../components/Footer"
import '../styles/main.scss'
import { useEffect } from "react"
import { useState } from "react"


const Layout = ({ pageId, theme, footer, logo, children,location}) => {

  const [headless,setHeadless] = useState('')
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  useEffect(()=>{
    setHeadless(location ? (location.search.split("=")[1]) : "");
  },[location])

  return (
    <>
     {headless === 'true' ? null :<Header siteTitle={data.site.siteMetadata.title} theme={theme} logo={logo} />}
      <main id={`page--${pageId}`}>{children}</main>
      {headless === 'true' ? null : footer && <Footer siteTitle={data.site.siteMetadata.title} /> }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
  logo: PropTypes.bool,
}

Layout.defaultProps = {
  footer: true,
  logo: true,
}

export default Layout
